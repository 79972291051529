import React, { useState } from 'react';
import FrameSelect from './components/FrameSelect';
import InputForm from './components/InputForm'
import { AppBar, Toolbar } from '@material-ui/core'
import PhotoSizeSelectLargeIcon from '@material-ui/icons/PhotoSizeSelectLarge'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import stockPic from './phone.jpg'
import stockVideo from './coverr-wave-top-shot-1559215758464.mp4'

function App() {
  const [image, setImage] = useState({ height: 920, width: 562 , src:null, file:null});
  const [videoFile, setVideoFile] = useState(null);
  const [moveableSettings, setMoveableSettings] = useState("resize");
  const [procParams, setProcParams] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  const getStockPic = async () => {
    const res = await fetch(stockPic)
    let blob = await res.blob()
    blob.lastModifiedDate = new Date();
    blob.name = 'stockPic.png'
    return { file: blob, url: stockPic}
  }

  const getStockVid = async () => {
    const res = await fetch(stockVideo)
    let blob = await res.blob()
    blob.lastModifiedDate = new Date();
    blob.name = 'stockVid.mp4'
    return { file: blob, url: stockVideo}
  }

  const isValidBackgroundType = (type) => {
    return type.split('\/')[0] === "image"
  }

  const isValidOverlayType = (type) => {
    return type.split('\/')[0] === "image" || type.split('\/')[0] === "video"
  }

  const handleImageDrop = (files) => {
    if(!isValidBackgroundType(files[0].type)) {
      setAlertOpen(true)
      return false
    }
    if (files[0].type.split('\/')[0] === "image") {
      let image = new Image()
      image.src = URL.createObjectURL(files[0])
      image.onload = () => setImage({ height: image.height, width: image.width, src:image.src, file: files[0]})
    }

    if (files[0].type.split('\/')[0] === "video") {
      setImage({ height: image.height, width: image.width, src:URL.createObjectURL(files[0]), file: files[0]})
    }
    return true
  }

  const handleVideoDrop = (files) => {
    if(!isValidOverlayType(files[0].type)) {
      setAlertOpen(true)
      return false
    }
    setVideoFile({type: files[0].type, video: URL.createObjectURL(files[0])})
    return true
  }

  const handleMovableSettingsChange = (setting) => {
    setMoveableSettings(setting)
  }

  const handleAlertClose = (event, reason) => {
    if(reason === "clickaway") return

    setAlertOpen(false)
  }


  return (
    <>
      <AppBar style={{height:"7%"}} position="static">
        <Toolbar style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <PhotoSizeSelectLargeIcon style={{marginRight: 20}} />
          <h1>WarpVid</h1>
        </Toolbar>
      </AppBar>
      <Snackbar open={alertOpen} autoHideDuration={5000} onClose={handleAlertClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleAlertClose} severity="error">
          Invalid file type!
        </MuiAlert>
      </Snackbar>
      <div style={{display:"flex", height:"93%"}}>
        <FrameSelect 
          moveableSettings={moveableSettings}  
          selectedVideo={videoFile} 
          image={image} 
          setProcParams={setProcParams}
          stockPic={stockPic}
          stockVideo={stockVideo}
        />
        <InputForm 
          style={{flex:1}} 
          moveableSettings={moveableSettings} 
          movableSettingsHandler={handleMovableSettingsChange} 
          imageDropHandler={handleImageDrop} 
          videoDropHandler={handleVideoDrop} 
          procParams={procParams} 
          getStockPic={getStockPic} 
          getStockVid={getStockVid}
        />
      </div>
    </>
  );
}

export default App;
