import React, { useState, useEffect } from 'react'
import { Button, FormLabel } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Checkmark} from 'react-checkmark';

import DialogActions from "@material-ui/core/DialogActions";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

// import Amplify, { Storage } from 'aws-amplify';
// import awsconfig from '../aws-exports';
// Amplify.configure(awsconfig);

const post = (fields, file, url) => {
    return new Promise(resolve =>
        { var data = new FormData();
            data.append("key", fields.key);
            data.append("AWSAccessKeyId", fields.AWSAccessKeyId);
            data.append("policy", fields.policy);
            data.append("signature", fields.signature);
            data.append("file", file);

            var xhr = new XMLHttpRequest();

            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    resolve(this.responseText);
                }
            });

            xhr.open("POST", url);
            xhr.send(data);}
    )
}

const InputForm = ({ imageDropHandler, videoDropHandler, movableSettingsHandler, moveableSettings, procParams, getStockPic, getStockVid }) => {
    const [imageThumbnail, setImageThumbnail] = useState({file:null, url:null});
    const [videoThumbnail, setVideoThumbnail] = useState({file:null, url:null});
    const [imageThumbnailUpdated, setImageThumbnailUpdated] = useState(false);
    const [videoThumbnailUpdated, setVideoThumbnailUpdated] = useState(false);
    const [createVideoModalOpen, setCreateVideoModalOpen] = useState(false);
    const [loadState, setLoadState] = useState("uninitiated");

    useEffect(() => {
        getStockPic().then(img => setImageThumbnail(img))
        getStockVid().then(vid => setVideoThumbnail(vid))
    }, [])

    const handleImageDrop = (files) => {
        if(imageDropHandler(files)) {
            setImageThumbnail( {file: files[0], url: URL.createObjectURL(files[0])} )
            setImageThumbnailUpdated(true)
        }
    };

    const handleVideoDrop = (files) => {
        if(videoDropHandler(files)) {
            setVideoThumbnail( {file: files[0], url: URL.createObjectURL(files[0])} )
            setVideoThumbnailUpdated(true)
        }
    };

    const uploadMedia = async (resultType, resultExt) => {

        setLoadState("loading")

        const imKey =  String(Date.now()) + imageThumbnail.file.name;
        const vidKey =  String(Date.now()) + videoThumbnail.file.name;

        let imPost = await fetch(`https://tgxlku2fui.execute-api.us-east-1.amazonaws.com/fthis/signer?key=${imKey}&method=POST`, {headers:{"x-api-key": "IVZJvw1n132qd9vd4aFK1BHuPQmQ1dS48aus8Ynh"},method: 'GET'})
        imPost = await imPost.json();
        await post(imPost.fields, imageThumbnail.file, imPost.url);

        let vidPost = await fetch(`https://tgxlku2fui.execute-api.us-east-1.amazonaws.com/fthis/signer?key=${vidKey}&method=POST`, {headers:{"x-api-key": "IVZJvw1n132qd9vd4aFK1BHuPQmQ1dS48aus8Ynh"},method: 'GET'})
        vidPost = await vidPost.json();
        await post(vidPost.fields, videoThumbnail.file, vidPost.url);

        let MULT = 2;

        const dimensions = {
            backgroundWidth : MULT*(procParams.imgRight - procParams.imgLeft), //imgWidtg
            backgroundHeight : MULT*( procParams.imgBottom - procParams.imgTop), //imgHeight
            overlayWidth : MULT*( procParams.vidRight - procParams.vidLeft), //vidWidth
            overlayHeight : MULT*( procParams.vidBottom - procParams.vidTop), //vidHeight
            overlayLeft : MULT*(procParams.vidLeft - procParams.imgLeft), //vidLeftFromImLeft
            overlayTop : MULT*( procParams.vidTop - procParams.imgTop),
            overlayX0: MULT*( procParams.vidX0 - procParams.imgLeft),
            overlayY0: MULT*( procParams.vidY0 - procParams.imgTop),
            overlayX1: MULT*( procParams.vidX1 - procParams.imgLeft),
            overlayY1: MULT*( procParams.vidY1 - procParams.imgTop),
            overlayX2: MULT*( procParams.vidX2 - procParams.imgLeft),
            overlayY2: MULT*( procParams.vidY2 - procParams.imgTop),
            overlayX3: MULT*( procParams.vidX3 - procParams.imgLeft),
            overlayY3: MULT*( procParams.vidY3 - procParams.imgTop)
        };

        // TODO: add 'resultType', 'resultExt', 'backgroundType' to request body
        let videoProcessingResult = await fetch('https://tgxlku2fui.execute-api.us-east-1.amazonaws.com/fthis/videooverlay', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                "x-api-key": "reX8zAg3WC3OpCmAshx565Kx3C8KH2ei4xwc81QU"
            },
            body: JSON.stringify({'x-api-key': "reX8zAg3WC3OpCmAshx565Kx3C8KH2ei4xwc81QU",
                backgroundType: imageThumbnail.file.type,
                overlayType: videoThumbnail.file.type,
                resultType: resultType,
                resultExt: resultExt,
                backgroundKey: imKey,
                overlayKey: vidKey,
                ...dimensions})}
        );

        videoProcessingResult = await videoProcessingResult.json();
        setLoadState("finished");

        let presignedFinalVideoGet = await fetch(`https://tgxlku2fui.execute-api.us-east-1.amazonaws.com/fthis/signer?key=${vidKey+"."+resultExt}&method=GET`, {
            headers:{ "x-api-key": "IVZJvw1n132qd9vd4aFK1BHuPQmQ1dS48aus8Ynh"}, method: 'GET'});
        presignedFinalVideoGet = await presignedFinalVideoGet.json();

        //setCreateVideoModalOpen(false);
        //setLoadState("uninitiated");

        let element = document.createElement('a');

        element.setAttribute('href', presignedFinalVideoGet.url);
        element.setAttribute('download', true);

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const handleVideoCreate = async (resultType, resultExt) => {
        await uploadMedia(resultType, resultExt);
    }

    let isBackgroundImg = null;
    let isBackgroundGIF = null;
    let isBackgroundVid = null;

    let isOverlayImg = null;
    let isOverlayGIF = null;
    let isOverlayVid = null;



    if(! imageThumbnail.file){
        isBackgroundImg = true;
        isBackgroundGIF = false;
        isBackgroundVid = false;
    }
    else{
        isBackgroundImg = (imageThumbnail.file.type.split('\/')[0] === "image") && (imageThumbnail.file.type !== "image/gif");
        isBackgroundGIF = imageThumbnail.file.type === "image/gif";
        isBackgroundVid = false;
    }

    if(!videoThumbnail.file){
        isOverlayImg = false;
        isBackgroundGIF = false;
        isBackgroundVid = true;
    }
    else{
        isOverlayImg = (videoThumbnail.file.type.split('\/')[0] === "image") && (videoThumbnail.file.type !== "image/gif");
        isOverlayGIF = videoThumbnail.file.type === "image/gif";
        isOverlayVid = videoThumbnail.file.type.split('\/')[0] === "video";
    }


    const isImgAvailable = isBackgroundImg && isOverlayImg;
    const isGIFAvailable = !isImgAvailable;
    const isVidAvailable = isBackgroundVid || isOverlayVid;



    return(
        <form style={{flex: 4, maxWidth: 300, marginTop: "5%", display:"flex", alignItems:"center", flexDirection:"column", overflowY : "scroll"}}>
            <FormLabel>Setting</FormLabel>
            <ToggleButtonGroup style={{margin:20}} size="large"  exclusive value={moveableSettings} onChange={(e,newSetting) => movableSettingsHandler(newSetting)}>
                <ToggleButton value="resize">
                    resize
                </ToggleButton>
                <ToggleButton value="warp">
                    warp
                </ToggleButton>
                <ToggleButton value="preview">
                    preview
                </ToggleButton>
            </ToggleButtonGroup>
            <FormLabel>Background</FormLabel>
            <Dropzone onDrop={handleImageDrop}>
                {({getRootProps, getInputProps}) => (
                    <section style={{border:"2px dashed lightgrey", display:"flex", flexDirection:"column", margin:20, padding:20, alignItems:"center", justifyContent:"center", textAlign:"center", borderRadius:10}}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                imageThumbnailUpdated
                                    ?
                                    <>
                                        {imageThumbnail.file && (imageThumbnail.file.type.split('\/')[0] === "video") && <video  src={imageThumbnail.url} style={{ maxHeight: "100px"}} />}
                                        {imageThumbnail.file && (imageThumbnail.file.type.split('\/')[0] === "image") && <img src={imageThumbnail.url} style={{maxHeight: "100px"}} />}
                                    </>
                                    : <p>Drag 'n' drop image/gif here, or click to select file</p>
                            }
                        </div>
                    </section>
                )}
            </Dropzone>
            <FormLabel>Overlay</FormLabel>
            <Dropzone onDrop={handleVideoDrop}>
                {({getRootProps, getInputProps}) => (
                    <section style={{border:"2px dashed lightgrey", display:"flex", flexDirection:"column", margin:20, padding:20, alignItems:"center", justifyContent:"center", textAlign:"center", borderRadius:10}}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                videoThumbnailUpdated
                                    ?
                                    <>
                                    {videoThumbnail.file && (videoThumbnail.file.type.split('\/')[0] === "video") && <video  src={videoThumbnail.url} style={{  maxHeight: "100px"}} />}
                                    {videoThumbnail.file && (videoThumbnail.file.type.split('\/')[0] === "image") && <img src={videoThumbnail.url} style={{ maxHeight: "100px"}} />}
                                    </>
                                    : <p>Drag 'n' drop image/gif/video here, or click to select file</p>
                            }
                        </div>
                    </section>
                )}
            </Dropzone>
            <br />
            <Button onClick={() => setCreateVideoModalOpen(true)} variant="contained" color="primary">
                create
            </Button>
            <Dialog
                style={{ zIndex: 3001 }}
                maxWidth={"xs"}
                fullWidth
                open={createVideoModalOpen}
                onClose={() => {setLoadState("uninitiated"); setCreateVideoModalOpen(false)}}
                aria-labelledby="max-width-dialog-title"
            >
                {createVideoModalOpen && <> <DialogTitle style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", textAlign:"center"}} id="max-width-dialog-title">
                    {loadState === "uninitiated" && "Ready to download"}
                    {loadState === "loading" && "This may take a few moments"}
                </DialogTitle>
                    <DialogContent style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                        {loadState === "loading" && <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        /> }
                        {loadState === "uninitiated" &&
                        <DialogContentText>
                            Would you like to see your masterpiece?
                        </DialogContentText>}
                        {loadState === "finished" && <Checkmark size='large'/>}
                    </DialogContent>
                    <DialogActions style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
                        { loadState === "uninitiated" &&
                        <>
                               {isImgAvailable && <Button variant={"contained"} onClick={() => handleVideoCreate("image/png", "png")} color="primary">
                                Image
                            </Button>}
                           { isGIFAvailable && <Button variant={"contained"} onClick={() => handleVideoCreate("image/gif", "gif")} color="primary">
                                GIF
                            </Button>}
                            {isVidAvailable && <Button variant={"contained"} onClick={() => handleVideoCreate("video/mp4", "mp4")} color="primary">
                                Video
                            </Button>}
                            <Button variant={"outlined"} onClick={() => {setCreateVideoModalOpen(false); setLoadState("uninitiated"); }} color="primary">
                                No
                            </Button>
                        </>
                        }
                    </DialogActions> </>}
            </Dialog>
        </form>
    )
}

export default InputForm