import React from "react";
import Moveable from "react-moveable";
import { ref } from "framework-utils";
import { Frame } from "scenejs";
import "./styles/selectorStyle.css"

class Selector extends React.Component {
  frame = new Frame({
    width: "250px",
    height: "200px",
    left: "0px",
    top: "0px",
    transform: {
      rotate: "0deg",
      scaleX: 1,
      scaleY: 1,
      matrix3d: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
    }
  });
  state = {
    target: null,
    container: null,
    scalable: false,
    resizable: true,
    warpable: false,
    rendered: false,
    bounds: null
  };
  render() {
    const { target, bounds} = this.state;
    console.log(this.props.video ? this.props.video.type : null)

    return (
      <>
        <Moveable
          style={{display: this.props.moveableSettings === "preview" ? "none" : "auto"}}
          ref={ref(this, "moveable")}
          target={target}
          pinchThreshold={20}
          container={document.body}
          snappable={true}
          bounds={bounds}
          draggable={true}
          scalable={false}
          resizable={this.props.moveableSettings === "resize"}
          warpable={this.props.moveableSettings === "warp"}
          rotatable={true}
          pinchable={false}
          origin={false}
          throttleDrag={1}
          throttleRotate={0.2}
          throttleResize={1}
          throttleScale={0.01}
          onDrag={this.onDrag}
          onResize={this.onResize}
          onScale={this.onScale}
          onWarp={this.onWarp}
          onRotate={this.onRotate}
          onPinch={this.onPinch}
          onDragEnd={this.onEnd}
          onScaleEnd={this.onEnd}
          onResizeEnd={this.onEnd}
          onWarpEnd={this.onEnd}
          onRotateEnd={this.onEnd}
          onPinchEnd={this.onEnd}
        />
        <div className="container" >
          <div className="moveable">
            { this.props.video === null && this.props.stockVideo && <video  src={this.props.stockVideo} style={{ pointerEvents: this.props.moveableSettings === "preview" ? "all" : "none", width: "100%", height: "100%", objectFit:"fill" }} controls muted autoPlay playsInline={true}/>}
            {this.props.video && (this.props.video.type.split('\/')[0] === "video") && <video  src={this.props.video.video} style={{ pointerEvents: this.props.moveableSettings === "preview" ? "all" : "none", width: "100%", height: "100%", objectFit:"fill" }} controls muted loop autoPlay playsInline={true}/>}
            {this.props.video && (this.props.video.type.split('\/')[0] === "image") && <img src={this.props.video ? this.props.video.video : this.props.stockVideo} style={{ pointerEvents: this.props.moveableSettings === "preview" ? "all" : "none", width: "100%", height: "100%", objectFit:"fill" }} controls/>}

          </div>
        </div>
        {/* <div className="label" ref={ref(this, "label")} /> */}
      </>
    );
  }
  componentDidMount() {
    this.setState({
      target: document.querySelector(".moveable")
    });
    window.addEventListener("resize", this.onWindowResize);
  }
  componentDidUpdate() {
    if(this.props.parentRendered && this.moveable.current !== null && !this.state.rendered){
      this.setProcessingState()
      this.setState({rendered:true})
    }
    this.onWindowResize()
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  setProcessingState() {
    const imgBounds = this.props.calcNewBounds()
    const vidBounds = this.moveable.getRect()
    this.props.setProcParams({
      imgTop: imgBounds.top,
      imgBottom: imgBounds.bottom,
      imgLeft: imgBounds.left,
      imgRight: imgBounds.right,
      vidTop: vidBounds.top,
      vidBottom: vidBounds.top + vidBounds.offsetHeight,
      vidLeft: vidBounds.left,
      vidRight: vidBounds.left + vidBounds.offsetWidth,
      vidX0: vidBounds.pos1[0],
      vidY0: vidBounds.pos1[1],
      vidX1: vidBounds.pos2[0],
      vidY1: vidBounds.pos2[1],
      vidX2: vidBounds.pos3[0],
      vidY2: vidBounds.pos3[1],
      vidX3: vidBounds.pos4[0],
      vidY3: vidBounds.pos4[1],
    })
  }

  onWindowResize = () => {
    this.moveable.updateRect();
    const newBounds = this.props.calcNewBounds();
    if (JSON.stringify(this.state.bounds) !== JSON.stringify(newBounds)){
      this.setState({bounds: newBounds});
    }
  };

  setTransform(target) {
    target.style.cssText = this.frame.toCSS();
  }
  setLabel(clientX, clientY, text) {
    this.label.style.cssText = `display: block; transform: translate(${clientX}px, ${clientY -10}px) 
      translate(-100%, -100%) translateZ(-100px);`;
    this.label.innerHTML = text;
  }
  onPinch = ({ clientX, clientY }) => {
    setTimeout(() => {
      this.setLabel(
        clientX,
        clientY,
        `X: ${this.frame.get("left")}
        <br/>Y: ${this.frame.get("top")}
        <br/>W: ${this.frame.get("width")}
        <br/>H: ${this.frame.get("height")}
        <br/>S: ${this.frame.get("transform", "scaleX").toFixed(2)}, ${this.frame
          .get("transform", "scaleY")
          .toFixed(2)}
        <br/>R: ${parseFloat(this.frame.get("transform", "rotate")).toFixed(1)}deg`
      );
    });
  };
  onDrag = ({ target, clientX, clientY, top, left, isPinch }) => {
    this.frame.set("left", `${left}px`);
    this.frame.set("top", `${top}px`);
    this.setTransform(target);
    // if (!isPinch) {
    //   this.setLabel(clientX, clientY, `X: ${left}px<br/>Y: ${top}px`);
    // }
    this.setProcessingState()
  };
  onScale = ({ target, delta, clientX, clientY, isPinch }) => {
    const scaleX = this.frame.get("transform", "scaleX") * delta[0];
    const scaleY = this.frame.get("transform", "scaleY") * delta[1];
    this.frame.set("transform", "scaleX", scaleX);
    this.frame.set("transform", "scaleY", scaleY);
    this.setTransform(target);
    // if (!isPinch) {
    //   this.setLabel(
    //     clientX,
    //     clientY,
    //     `S: ${scaleX.toFixed(2)}, ${scaleY.toFixed(2)}`
    //   );
    // }
  };
  onRotate = ({ target, clientX, clientY, beforeDelta, isPinch }) => {
    const deg = parseFloat(this.frame.get("transform", "rotate")) + beforeDelta;

    this.frame.set("transform", "rotate", `${deg}deg`);
    this.setTransform(target);

    this.setProcessingState()
  };
  onResize = ({ target, clientX, clientY, width, height, isPinch, offsetWidth, dist, delta, direction}) => {

    this.frame.set("width", `${width}px`);
    this.frame.set("height", `${height}px`);

    let left = Number(this.frame.get("left").slice(0,-2));
    let top = Number(this.frame.get("top").slice(0,-2));


    let newLeft = direction[0] === 1 ?
        left + delta[0]/2 :
          direction[0] === -1 ?
              left - delta[0]/2 : left;

    let newTop = direction[1] === 1 ?
        top + delta[1]/2 :
        direction[1] === -1 ?
            top - delta[1]/2 : top;


    this.frame.set("left", `${newLeft}px`);
    this.frame.set("top", `${newTop}px`);

    this.setTransform(target);
    // if (!isPinch) {
    //   this.setLabel(clientX, clientY, `W: ${width}px<br/>H: ${height}px`);
    // }
    this.setProcessingState()
  };
  onWarp = ({ target, clientX, clientY, delta, multiply }) => {
    this.frame.set(
      "transform",
      "matrix3d",
      multiply(this.frame.get("transform", "matrix3d"), delta)
    );
    this.setTransform(target);
    // this.setLabel(clientX, clientY, `X: ${clientX}px<br/>Y: ${clientY}px`);
    this.setProcessingState()
  };
  onEnd = () => {
    // this.label.style.display = "none";
  };
}

export default Selector