import React, {useRef} from 'react';
import Selector from './Selector'

const FrameSelect = ({ image, selectedVideo, moveableSettings, setProcParams, stockPic, stockVideo }) => {

  const parent = useRef(null)

  const calcNewBounds = () => {
    const parentCurrent = parent.current;

    const parentHeight = parentCurrent.offsetHeight;
    const parentWidth = parentCurrent.offsetWidth;
    const parentLeft = parentCurrent.getBoundingClientRect().left;
    const parentRight = parentCurrent.getBoundingClientRect().right;
    const parentTop = parentCurrent.getBoundingClientRect().top;
    const parentBottom = parentCurrent.getBoundingClientRect().bottom;

    const thumbWidth = image.width;
    const thumbHeight = image.height;

    let scaleFactor;
    let imWidth;
    let imHeight;
    let imLeft;
    let imRight;
    let imTop;
    let imBottom;

    if(thumbHeight / thumbWidth < parentHeight / parentWidth) {
      scaleFactor = parentWidth / thumbWidth;

      imLeft=parentLeft;
      imWidth = parentWidth;
      imRight = parentLeft + parentWidth;
      imHeight = thumbHeight * scaleFactor;

      imTop = parentTop + (parentHeight - imHeight)/2;
      imBottom = imTop + imHeight;
    }
    else {
      scaleFactor = parentHeight / thumbHeight;

      imTop=parentTop;
      imHeight = parentHeight;
      imBottom = parentTop + parentHeight;
      imWidth = thumbWidth * scaleFactor;

      imLeft = parentLeft + (parentWidth - imWidth)/2;
      imRight = imLeft + imWidth;
    }

    return parentCurrent ? {left: imLeft, right: imRight , top: imTop , bottom: imBottom } : null;
  }

  return(

    <>

      {(image.file && (image.file.type.split('\/')[0] === "image") || !image.src)   &&

      <div
      ref={parent}
      style={{
        flex:8,
        borderRight: "1px solid lightgrey",
        backgroundSize: "contain",
        height:"100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition:"center",
        backgroundImage: `url("${image.src ? image.src : stockPic}")`}}>
      <Selector
        calcNewBounds={calcNewBounds}
        parentRendered={parent !== null}
        moveableSettings={moveableSettings}
        video={selectedVideo}
        setProcParams={setProcParams}
        stockVideo={stockVideo}
      />
    </div>}
    </>
  )
};

export default FrameSelect